import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

const getData = graphql`
    query {
        site {
            siteMetadata {
                siteTitle: title
                siteDesc: description
                author
                siteUrl
                image
                twitterUsername
            }
        }
    }
`;
//TODO: Make multiple tags available
const SEO = ({ title, metaDescription, pageImage, pathname, type, keyword, readingTime }) => {
    const { site } = useStaticQuery(getData);
    const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : site.siteMetadata.siteUrl

    const {
        siteDesc,
        siteTitle,
        siteUrl,
        image,
        twitterUsername,
        author
    } = site.siteMetadata;

    SEO.propTypes = {
        title: PropTypes.string,
        metaDescription: PropTypes.string,
        pageImage: PropTypes.string,
        pathname: PropTypes.string,
        type: PropTypes.string,
        keyword: PropTypes.string,
        readingTime: PropTypes.number,
    }

    SEO.defaultProps = {
        title: siteTitle,
        metaDescription: siteDesc,
        pageImage: image,
        type: "website",
        keyword: "Caspar Geerlings",
        readingTime: 0,
    }

    return (
        <Helmet htmlAttributes={{ lang: "en" }} title={title} link={canonical? [{rel: "canonical",href: canonical,},]: []}>
            <html prefix="og: https://ogp.me/ns#" />
            <meta name="description" content={metaDescription} />
            <meta name="image" content={`${siteUrl}${pageImage}`} />
            <meta name="keywords" content={"Caspar Geerlings, " + `${keyword}`} />
            <meta name="author" content={author} />
            {/*general*/}
            <meta name="og:email" content="contact@caspargeerlings.com" />
            <meta name="og:site_name" content={siteTitle} />
            {/* facebook cards */}
            <meta property="og:url" content={canonical} />
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={`${siteUrl}${pageImage}`} />
            <meta property="og:image:width" content="400" />
            <meta property="og:image:height" content="300" />
            {/* twitter card */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={twitterUsername} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={metaDescription} />
            <meta name="twitter:image" content={`${siteUrl}${pageImage}`} />
            <meta name="twitter:label1" content={readingTime != 0 ? "Reading time" : null} />
            <meta name="twitter:data1" content={`${readingTime} minutes`} />
        </Helmet>
    )
};

export default SEO
